import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import {
  Embellishment0,
  Embellishment20,
  Embellishment21,
  Embellishment22,
} from "../components/Embellishments"
import Layout from "../components/Layout"

function Section1({ title }) {
  return (
    <div className="section">
      <div className="padding---subpage-hero">
        <div className="subpage-hero-interior-padding background---black">
          <h1 className="h1 text---white">{title}</h1>
          <div className="notch---subpage-hero-1">
            <Embellishment20 />
          </div>
          <div className="notch---subpage-hero-2">
            <Embellishment21 />
          </div>
          <Embellishment22 />
        </div>
      </div>
    </div>
  )
}

function Section2({ news, press }) {
  return (
    <div className="section">
      <div className="xl---padding-12---1-col xl---flex spacing---lg md---block">
        <div className="xl---width-10---5 float---left xl---padding-10---1-col-right center-column-border md---full-width md---no-float">
          <h6 className="h6 label spacing---lg">{news.title}</h6>
          {news.list.map((item, index) => (
            <div key={`news-item-${index}`} className="news-entry-wrapper">
              <h4 className="h4 spacing---md">{item.label}</h4>
              <p
                className="spacing---pmd"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              <a href={item.cta.url} className="cta">
                {item.cta.label}
              </a>
            </div>
          ))}
        </div>
        <div className="xl---width-10---5 float---left md---full-width md---no-float xl---padding-10---1-col-left md---padding---0">
          <h6 className="h6 label spacing---lg">{press.title}</h6>
          {press.list.map((item, index) => (
            <div key={`press-item-${index}`} className="news-entry-wrapper">
              <h4 className="h4 spacing---md">{item.label}</h4>
              <p
                className="spacing---md"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              <a href={item.cta.url} className="cta">
                {item.cta.label}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Embellishment0 />
      </div>
    </div>
  )
}

function NewsPage({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <Section1 {...post.frontmatter.hero} />
      <Section2 {...post.frontmatter.section} />
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default NewsPage

export const query = graphql`
  query NewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        hero {
          title
        }
        section {
          news {
            title
            list {
              label
              description
              cta {
                url
                label
              }
            }
          }
          press {
            title
            list {
              label
              description
              cta {
                url
                label
              }
            }
          }
        }
      }
    }
  }
`
